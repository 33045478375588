.no-shadow {
  box-shadow: none;
}

.BookingCard {
    max-width: 720px;
    margin: 0 auto 10px;
    padding: 20px;

    &__container {
      max-width: 720px;
      margin: 0 auto 10px;
      button {
        box-shadow: var(--bokamera-box-shadow);
      }
    }
  
    &__img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      background-clip: padding-box;
      background-position: center center;
      background-size: cover;
      -moz-background-clip: padding;
      margin-bottom: 20px;
      &--small {
        width: 50px;
        height: 50px;
        margin-bottom: 0;
      }
    }
    &__flex {
      transition: all 0.7s cubic-bezier(0.45, 0, 0.55, 1);
      display: flex;
      &.itemCenter {
        align-items: center;
      }
      &.justifyCenter {
        justify-content: center;
      }
    }
    &__left {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    @media only screen and (max-width: 600px) {
      &__left {
        display: none;
      }
    }
    &__right {
      margin-left: 40px;
      flex-grow: 1;
    }
    &__serviceName {
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 12px;
    }
    &__cardReview {
      overflow: hidden;
      transition: max-height 0.7s cubic-bezier(0.45, 0, 0.55, 1);
      &__heading {
        font-weight: 600;
        font-size: 20px;
        margin-top: 20px;
        margin-bottom: 12px;
      }
    }
    &__subtitle {
      margin-bottom: 12px;
    }
    &__id {
      color: #888;
    }
    &__datetime {
      margin-bottom: 12px;
    }
    &__time {
      display: inline-block;
    }
    &__date {
      display: inline-block;
    }
    &__descriptionList {
      max-width: 320;
      margin: 0;
      display: flex;
    }
    &__descriptionTitle {
      font-weight: bold;
      margin-right: 10px;
    }
    &__descriptionDetails {
      text-align: right;
    }
    @media only screen and (max-width: 600px) {
      &__descriptionList {
        flex-direction: column;
      }
      &__descriptionDetails {
        text-align: left;
      }
    }
    &__action {
      margin-bottom: 10px;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__formError {
      margin-top: 15px;
      color: var(--bokamera-danger);
    }
    &__textarea {
      line-height: 1.5;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border: 1px solid var(--bokamera-input-border);
      &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
    &__input {
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      /* border: 1px solid var(--bokamera-input-border); */
      border: 1px solid red;
      line-height: 1.5;
      height: calc(2.5rem + 0.75rem + 2px);
      &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
      }
    }
    @media only screen and (max-width: 800px) {
      &__time {
        font-size: 1.2rem;
      }
      &__img {
        width: 80px;
        height: 80px;
      }
    }
    @media only screen and (max-width: 600px) {
      &__img {
        width: 48px;
        height: 48px;
      }
      &__right {
        margin-left: 16px;
      }
      &__time {
        font-size: 1.2rem;
      }
    }
    &__SmallCard {
      transition: all 0.7s cubic-bezier(0.45, 0, 0.55, 1);
      .BookingCard__right {
        transition: all 0.7s cubic-bezier(0.45, 0, 0.55, 1);
        justify-content: center;
        display: flex;
        margin: 0;
        transform: translate(0px, 0px);
      }
      &.active {
        @media only screen and (max-width: 600px) {
          min-width: 100% !important;
        }
        .BookingCard__right {
          transform: translate(-25px, 0px);
        }
      }
    }
    &__serviceName {
      margin-top: 10px;
      font-weight: bold;
    }
    &__teaserList {
      display: flex;
      margin-left: -8px;
      margin-right: -8px;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
      @media only screen and (max-width: 600px) {
        margin-left: auto;
        margin-right: auto;
      }
      .card {
        padding: 8px;
        margin-left: 8px;
        margin-right: 8px;
        scroll-snap-align: start;
        @media only screen and (max-width: 600px) {
          min-width: 90%;
        }
        flex-grow: 1;
        min-width: 300px;
        max-width: 300px;
      }
      .alert {
        flex-basis: calc(25% - 16px);
      }
    }
  }

