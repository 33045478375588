#bokamera-embedded {
    .Calendar {
        position: relative;
        text-align: center;

        &__header {
            display: flex;
        }
        &__body {
            display: flex;
            flex-direction: column;
        }
        &__headerCell {
            padding: 8px 0;
        }
        &__weekRow {
            display: flex;
        }
        &__dayCol {
            width: calc(100% / 8);
            margin: 1px;
        }
        &__weekNumberCol {
            width: calc(100% / 8);
            padding: 8px 0;
        }
        &__day {
            width: 100%;
            white-space: normal;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            padding: 8px 0;
            color: #000;

            &--hasFreeTimes {
                background: var(--bokamera-success-light);
                &:hover {
                    background: var(--bokamera-success);
                }
            }
            &--today {
                font-weight: bold;
            }
            &--selected {
                background: var(--bokamera-success);
            }
            &--booked {
                text-decoration: line-through;
                background: var(--bokamera-grey-200);
                color: var(--bokamera-grey-600);
            }
            &--noTimes {
                background: none;
                color: var(--bokamera-grey-500);
            }
            &--anotherMonth {
                background: none;
                color: #ccc;
            }

            &:disabled {
                cursor: auto;
            }
        }

        @media (max-width: 600px) {
            font-size: 12px;
        }
    }
}
