#bokamera-embedded {
    .Completed {
        &__userInitiatedPayment {
            display: flex;
            justify-content: center;
            margin-bottom: 36px;
        }
        &__paymentLoading {
            margin: 0 auto 1em;
        }
        &__body {
            margin-bottom: 15px;
        }
    }
}
