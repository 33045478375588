#bokamera-embedded {
    .Quantities {
        &__quantity::before {
            content: '×';
            position: absolute;
            transform: translateX(-0.7em);
        }
        &__totalRow {
            border-top: 1px solid #ccc;
        }
        &__discountRow {
            color: var(--bokamera-text-success);
        }
        &__discountAmount::before {
            content: '-';
            position: absolute;
            transform: translateX(-0.7em);
        }
    }
}
