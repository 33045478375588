#bokamera-embedded {
    .CustomerPromoCodes {
        .full-width {
            flex-basis: 100%;
        }

        &__flex {
            display: flex;
        }
        
        &__card.card {
            display: flex;
            padding: 6px;
            
        }
        &__left {
            flex-grow: 1;
            align-items: center;
            flex-basis: 80%;
            
        }
        &__right.card-body {
            display: flex;
            justify-content: flex-end;
            flex-shrink: 1;
        }
    }
}