@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap");
@import "reboot.css";

:host {
  display: block;
  clear: both;
  width: 100%;
}

:host, :root {
  --white: 255, 255, 255;
  --black: 0, 0, 0;
  --bokamera-white: rgb(var(--white));
  --bokamera-black: rgb(var(--black));
  --grey-100: 248, 250, 252;
  --grey-200: 241, 245, 248;
  --grey-300: 218, 225, 231;
  --grey-400: 184, 194, 204;
  --grey-500: 135, 149, 161;
  --grey-600: 96, 111, 123;
  --grey-700: 61, 72, 82;
  --grey-800: 34, 41, 47;
  --primary: 0, 123, 255;
  --primary-active: 0, 105, 217;
  --success-light: 203, 242, 220;
  --success: 142, 221, 176;
  --text-success: 60, 148, 18;
  --danger-light: 211, 166, 166;
  --warning-light: 233, 175, 70;
  --danger: 255, 88, 66;
  --bokamera-primary: rgb(var(--primary));
  --bokamera-primary-active: rgb(var(--primary-active));
  --bokamera-success-light: rgb(var(--success-light));
  --bokamera-success: rgb(var(--success));
  --bokamera-text-success: rgb(var(--text-success));
  --bokamera-danger-light: rgb(var(--danger-light));
  --bokamera-warning-light: rgb(var(--warning-light));
  --bokamera-danger: rgb(var(--danger));
  --bokamera-grey-800: rgb(var(--grey-800));
  --bokamera-grey-700: rgb(var(--grey-700));
  --bokamera-grey-600: rgb(var(--grey-600));
  --bokamera-grey-500: rgb(var(--grey-500));
  --bokamera-grey-400: rgb(var(--grey-400));
  --bokamera-grey-300: rgb(var(--grey-300));
  --bokamera-grey-200: rgb(var(--grey-200));
  --bokamera-grey-100: rgb(var(--grey-100));
  --bokamera-border: 1px solid rgba(var(--grey-200), 0.5);
  --bokamera-box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
    0 0 0 1px rgba(10, 10, 10, 0.02);
  --bokamera-bg-color: rgba(var(--bokamera-white), 0.07);
  --bokamera-text-color: var(--bokamera-grey-800);
  --bokamera-btn-bg: var(--bokamera-grey-300);
  --bokamera-btn-bg-active: var(--bokamera-grey-200);
  --bokamera-input-bg: var(--bokamera-grey-200);
  --bokamera-input-border: var(--bokamera-grey-300);
  * {
    box-sizing: border-box;
  }
  font-size: 16px;
}

[data-theme="dark"] body {
  background: var(--bokamera-grey-200);
}
:root[data-theme="dark"] {
  --white: 0, 0, 0;
  --black: 255, 255, 255;
  --bokamera-white: rgb(var(--white));
  --bokamera-black: rgb(var(--black));
  --primary: 93, 169, 251;
  --grey-800: 248, 250, 252;
  --grey-700: 241, 245, 248;
  --grey-600: 218, 225, 231;
  --grey-500: 184, 194, 204;
  --grey-400: 135, 149, 161;
  --grey-300: 96, 111, 123;
  --grey-200: 61, 72, 82;
  --grey-100: 34, 41, 47;
  --bokamera-primary: rgb(var(--primary));
  --bokamera-primary-active: rgb(var(--primary-active));
  --bokamera-success-light: rgb(var(--success-light));
  --bokamera-success: rgb(var(--success));
  --bokamera-bg-color: rgba(var(--bokamera-white), 0.07);
  --bokamera-text-success: rgb(var(--text-success));
  --bokamera-danger-light: rgb(var(--danger-light));
  --bokamera-danger: rgb(var(--danger));
  --bokamera-grey-800: rgb(var(--grey-800));
  --bokamera-grey-700: rgb(var(--grey-700));
  --bokamera-grey-600: rgb(var(--grey-600));
  --bokamera-grey-500: rgb(var(--grey-500));
  --bokamera-grey-400: rgb(var(--grey-400));
  --bokamera-grey-300: rgb(var(--grey-300));
  --bokamera-grey-200: rgb(var(--grey-200));
  --bokamera-grey-100: rgb(var(--grey-100));
  --border-color: rgba(var(--grey-200), 0.5);
  --border-width: 1px;
  --border-style: solid;
  --bokamera-border: 1px solid var(--border-color);
  --bokamera-box-shadow: none;
  --bokamera-bg-color: rgba(var(--black), 0.07);
  --bokamera-text-color: var(--bokamera-grey-800);
  --bokamera-btn-bg: var(--bokamera-grey-300);
  --bokamera-btn-bg-active: var(--bokamera-grey-200);
  --bokamera-input-bg: var(--bokamera-grey-200);
  --bokamera-input-border: var(--bokamera-grey-300);
}

#bokamera-embedded {
  font-family: "Roboto", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  white-space: pre-line;
  color: var(--bokamera-grey-800);

  .container,
  .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
  .card {
    border: var(--bokamera-border);
    box-shadow: var(--bokamera-box-shadow);
    background-color: var(--bokamera-bg-color);
    margin-bottom: 1rem;
  }
  .nav-tabs .nav-link {
    cursor: pointer;
  }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    border: var(--bokamera-border);
    background-color: var(--bokamera-bg-color);
    border-bottom-color: var(--bokamera-grey-300);
  }
  .nav-tabs .nav-link:hover,
  .nav-tabs .nav-link:focus {
    border-color: var(--bokamera-grey-300);
    border-bottom-color: var(--bokamera-bg-color);
  }
  .nav-tabs .nav-link.active {
    color: var(--bokamera-primary);
    background-color: var(--bokamera-bg-color);
    border-bottom: 1px solid var(--bokamera-primary);
    z-index: 1;
  }
  .nav-tabs {
    border-bottom: 1px solid var(--bokamera-grey-300);
  }
  .form-control,
  .form-control:focus {
    border: 1px solid var(--bokamera-input-border);
    background-color: var(--bokamera-input-bg);
    color: var(--bokamera-text-color);
  }
  .form-control:disabled {
    background-color: var(--bokamera-input-bg);
    color: var(--bokamera-grey-300);
  }
  .btn-secondary {
    border-color: var(--bokamera-grey-300);
    background-color: var(--bokamera-btn-bg);
    color: var(--bokamera-text-color);
  }
  .dropdown-menu {
    border: 1px solid var(--bokamera-grey-300);
  }
  .dropdown-menu,
  .dropdown-item {
    background-color: var(--bokamera-grey-200);
    color: var(--bokamera-text-color);
  }
  .btn-secondary:hover {
    background-color: var(--bokamera-btn-bg-active);
    border-color: var(--bokamera-grey-400);
    color: var(--bokamera-text-color);
  }
  .dropdown-item:hover {
    color: var(--bokamera-primary);
  }
  .table {
    color: var(--bokamera-text-color);
  }
  .table__cell--compact {
    width: 12rem;
  }

  .spinner-border-sm {
    width: 1em;
    height: 1em;
    border-width: .2em
  }

  @media only screen and (max-width: 760px) {
    .table__cell--compact {
      width: auto;
    }
  }

    /*
      Max width before this PARTICULAR table gets nasty
      This query will take effect for any screen smaller than 760px
      and also iPads specifically.
    */
  @media only screen and (max-width: 760px),
    (min-device-width: 768px) and (max-device-width: 1024px) {
    /* Force table to not be like tables anymore */
    .responsive-table table,
    .responsive-table thead,
    .responsive-table tbody,
    .responsive-table th,
    .responsive-table td,
    .responsive-table tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    .responsive-table thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    .responsive-table td {
      /* Behave  like a "row" */
      border: none;
      position: relative;
      padding-left: 50%;
    }
    .responsive-table
      tr:not(:last-of-type)
      > td:last-of-type:not(:only-of-type) {
      border-bottom: 1px solid #ccc;
    }

    .responsive-table td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }
  }

  .table {
    margin-bottom: 0;
  }

  .table__cell--collapsed {
    width: 1px;
    padding-right: 3em;
  }

  .Steps {
    margin-bottom: 12px;
  }

  .modal {
    backdrop-filter: brightness(1.6);
    background-color: var(--white);
  }
  .modal-content {
    background-color: var(--bokamera-white);
    
    border: var(--bokamera-border);
  }
  .close {
    color: var(--bokamera-text-color)!important;
  }
  .Button {
    font-size: 16px;
  }
  .form-control {
    font-size: 16px;
  }
}

.grey-100 {
  color: var(--bokamera-grey-100)
}

.grey-200 {
  color: var(--bokamera-grey-200)
}

.grey-300 {
  color: var(--bokamera-grey-300)
}

.grey-400 {
  color: var(--bokamera-grey-400)
}

.grey-500 {
  color: var(--bokamera-grey-500)
}

.grey-600 {
  color: var(--bokamera-grey-600)
}

.grey-700 {
  color: var(--bokamera-grey-700)
}

.grey-900 {
  color: var(--bokamera-grey-900)
}

#bokamera-embedded.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

@media print {
  body * {
    visibility: hidden;
  }
  #bokamera-embedded {
    #section-to-print,
    #section-to-print * {
      visibility: visible;
    }
    #section-to-print {
      position: absolute;
      left: 0;
      top: 0;
    }
    #section-to-print .hidden-print,
    #section-to-print .hidden-print * {
      visibility: hidden;
    }
  }
}
