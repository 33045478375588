#bokamera-embedded {
    .Stepper {
        display: flex;
        align-items: center;
        padding: 20px 0 28px;
        font-size: 16px;
        margin: 0 auto;

        &__step {
            display: flex;
            padding-left: 20px;
            padding-right: 20px;
            transition: transform ease 0.1s;

            &--active > .Stepper__stepCircle {
                background-color: var(--bokamera-primary);
                transition: background-color 0.2s ease-in;
            }
            &--done > .Stepper__stepCircle {
                background: none;
                border: 1px solid var(--bokamera-primary);
                color: var(--bokamera-primary);
            }
            &--active .Stepper__stepLabel,
            &--done .Stepper__stepLabel {
                color: inherit;
            }
            &--done:hover {
                transform: scale(1.1);
            }
            &--done:hover .Stepper__stepCircle {
                background-color: var(--bokamera-primary);
                border: #fff;
                color: #fff;
            }
            &--disabled {
                cursor: default;
                pointer-events: none;
                .Stepper__stepCircle {
                    background-color: var(--bokamera-grey-400);
                    border: var(--bokamera-grey-400);
                    color: var(--bokamera-white);
                }
            }
            &:first-of-type {
                padding-left: 0;
            }
            &:last-of-type {
                padding-right: 0;
            }
            &--done {
                cursor: pointer;
            }
        }

        &__stepCircle {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            color: #fff;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            background-color: var(--bokamera-grey-400);
        }
        &__stepLabel {
            color: var(--bokamera-grey-400);
        }
        &__connector {
            position: relative;
            flex: 1 1 auto;
            height: 1px;
            background-color: var(--bokamera-grey-400);
        }
        &__connectorFill {
            height: 100%;
            width: 0;
            background-color: var(--bokamera-primary);
            transition: width 0.2s ease-out;
        }
        &__connectorFill--done {
            width: 100%;
        }
    }
}
