#bokamera-embedded {
    .Counter {
        display: flex;
        justify-content: center;
    }
    .Counter__button {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid var(--bokamera-grey-800);
        background: none;
        color: var(--bokamera-grey-800);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .Counter__button > svg * {
        stroke: var(--bokamera-text-color);
    }
    .Counter__button:hover {
        border-color: #afb4bf;
    }
    .Counter__button:disabled {
        border-color: #afb4bf;
    }
    .Counter__count {
        display: inline-block;
        text-align: center;
        width: 47px;
    }
}
