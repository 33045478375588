.ResourceMini {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;

    &__img {
        border: 4px solid transparent;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        position: relative;
        object-fit: cover;
        padding: 2px;
    }
    &--active &__img {
        border-color: var(--bokamera-primary);
    }
}
