.LinkButton {
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
    border: none;
    background: none !important;
    padding: 0 !important;

    :hover {
        color: #0056b3;
        text-decoration: underline;
    }
}
