#bokamera-embedded {
    .TimesList {
        width: auto;
        margin: auto;

        &__row {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-around;
            padding: 1em;
            margin-bottom: 0.7em;
            align-items: baseline;
            border: 1px solid rgba(240, 237, 237, 0.5);
            box-shadow: 0px 2px 4px rgba(218, 221, 227, 0.8);
        }

        &__month {
            font-size: 1.2em;
            text-align: center;
            margin: 1em 0 0.3em;
        }

        &__date {
            font-weight: 600;
        }

        &__time {
            line-height: normal;
        }

        &__mobileOnly {
            display: none;
        }

        &__flex {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @media (max-width: 600px) {
            &__param {
                width: 100%;
            }

            &__param:not(:last-child) {
                margin-bottom: 0.5em;
            }

            &__card {
                align-items: stretch;
            }

            &__label {
                width: 30%;
                display: inline-block;
                text-align: right;
                margin-right: 1em;
            }

            &__row {
                flex-direction: column;
            }
        }
    }
}
