#bokamera-embedded {
  .Service {
    display: flex;
    justify-content: initial;

    margin: 0 auto 15px;

    background: var(--bokamera-bg-color);
    mix-blend-mode: normal;
    border: var(--bokamera-border);
    box-shadow: var(--bokamera-box-shadow);
    border-radius: 5px;
    padding: 20px;

    .column {
      display: flex;
      flex-direction: column;
      &.end {
        align-items: flex-end;
      }
    }
    .flex {
      display: flex;
    }
    .gap-3 {
      gap: 3px;
    }
    .gap-6 {
      gap: 6px;
    }
    .grow {
      flex-grow: 1;
    }
    .justify-content-end {
      justify-content: end;
    }
    .wrap {
      flex-wrap: wrap;
    }
    .hide {
      display: none;
    }

    &__info-icon {
      cursor: pointer;
      width: 40px;
      height: 40px;
      position: absolute;
      left: 12px;
      top: 0;
      z-index: 100;
    }
    &__title {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 16px;
    }
    &__imageContainer {
      width: 144px;
      align-self: flex-start;
      flex: 1 0 auto;
    }
    &__image,
    &__imagePlaceholder {
      width: 100%;
      border-radius: 5px;
    }
    &__image {
      object-fit: cover;
    }
    &__imagePlaceholder {
      height: 128px;
    }
    &__main {
      width: 100%;
      display: flex;
    }
    &__meta {
      display: flex;
      color: var(--bokamera-grey-400);
      align-items: center;
      margin-bottom: 16px;
      line-height: 14px;
      font-size: 14px;
    }
    &__metaDivider {
      margin: 0 10px;
      height: 1em;
      width: 0px;
      border-left: 1px solid var(--bokamera-grey-300);
    }
    &__metaIcon {
      margin-right: 5px;
      stroke: var(--bokamera-grey-400);
    }
    &__customFields {
      margin-bottom: 1rem;
    }
    &__customFieldLabel {
      padding-right: 1em;
      color: var(--bokamera-grey-400);
      vertical-align: top;
      padding-bottom: 10px;
    }
    &__customFieldValue {
      vertical-align: top;
      font-style: italic;
      padding-bottom: 10px;
    }
    &__content {
      width: 100%;
      padding: 0 36px;
    }
    &__priceContainer {
      margin-bottom: 13px;
      text-align: right;
      
    }
    &__price {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-items: baseline;
      margin-bottom: 6px;
      &.with-details {
        flex-wrap: nowrap;
        align-items: center;
      }
    }
    &__priceLabel {
      color: var(--bokamera-grey-400);
    }
    &__priceValue {
      margin-left: 0.3em;
      font-size: 22px;
      font-weight: 600;
      display: flex;
      gap: 3px;
      white-space: nowrap;
    }
    &__priceValuePrefix, &__category {
      color: var(--bokamera-grey-400);
    }
    &__price-info {
      position: relative;

      display: flex;
      gap: 6px;
      color: var(--bokamera-grey-300);
    }
    &__divider {
      border-left-color: var(--bokamera-grey-300);
      border-left-width: 1px;
      border-left-style: solid;
      height: 100%;
    }
    &__actionBox {
      display: flex;
      align-self: center;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding: 0 30px;
      position: relative;
      &.wide-details {
        min-width: 270px;
      }
    }
    &__select {
      width: 116px;
    }
    &__td-description {
      padding-right: 1rem;
    }

    &--column {
      margin: 0;
      padding: 0;
      height: 100%;
      .card-body {
        display: flex;
        flex-direction: column;
        .Button--primary {
          margin-top: auto;
        }
      }
      .card-title {
        font-weight: bold;
      }
    }
  }

  @media (max-width: 600px) {
    .Service {
      &__main {
        display: block;
      }
      &__content,
      &__actionBox {
        padding: 0 12px;
      }
      &__select {
        width: 100%;
      }
      &__imageContainer {
        width: 48px;
      }
      &__imagePlaceholder {
        width: 48px;
        height: 48px;
      }
      &__divider {
        display: none;
      }
      &__priceValue,
      &__priceSign {
        font-weight: normal;
      }
      &__select {
        float: right;
      }
    }
  }
}
