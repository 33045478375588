.slider-wrapper {
  padding: 12px 12px 24px;
}

.rc-slider-rail {
  background-color: rgba(0, 0, 0, 0.05);
}

.rc-slider-track {
  background-color: transparent;
}

.rc-slider-handle {
  border-color: var(--bokamera-primary);
  background-color: var(--bokamera-white);        
}
.rc-slider-handle:hover {
  box-shadow: 0 0 0 5px var(--bokamera-primary)!important;
  border-color: var(--bokamera-primary)!important;
}
.rc-slider-handle:focus-visible {
  box-shadow: 0 0 0 5px var(--bokamera-primary)!important;
  border-color: var(--bokamera-primary)!important;
}
.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px var(--bokamera-primary)!important;
  border-color: var(--bokamera-primary)!important;
}
.rc-slider-dot.rc-slider-dot-active {
  background-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.1);
}

.rc-slider-dot {
  background-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.1);
}

.rc-slider-mark-text-active,
.rc-slider-mark-text {
  color: var(--black);
}

.error {
  margin-top: 16px;
  border-radius: 4px;
  border: 1px solid var(--bokamera-danger);
  color: var(--bokamera-danger);
  padding: 8px;
}

.slider-wrapper .custom-select {
  max-width: 200px;
}