#bokamera-embedded {
  .Services {
    &--column &__title {
      margin-top: 20px;
      margin-left: 15px;
    }
  }

  .Services__ServicesRowsGrouped {
    .group-anchors {
      margin-bottom: 8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .group-anchors__item {
      margin-right: 8px;
      margin-bottom: 8px;
      padding: 8px;
      box-shadow: var(--bokamera-box-shadow);
      color: var(--bokamera-primary);
      border-radius: 10px;
    }
    .Services__collapsible-row {
      cursor: pointer;
      .collapsible-card {
        padding: 16px;
      }

      h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
      }

      .icon {
        width: 1.2rem;
        height: 1.2rem;
        transition: transform 0.3s ease-in-out;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
        opacity: 0.6;
        &--expanded {
          transform: rotateZ(-179.99deg);
        }
      }
    }
  }
}
