#bokamera-embedded {
  .CalendarWeek {
    position: relative;
    &__weekOverview {
      .timeslot {
        &--addToQueue {
          border: 2px solid var(--bokamera-warning-light);;
        }
      }
      @media (max-width: 600px) {
        overflow-x: scroll;
      }
      .CalendarWeek__column {
        @media (max-width: 600px) {
          min-width: 30%;
        }
      }
      .CalendarWeek__dayHeader {
        @media (max-width: 600px) {
          min-width: 30%;
        }
      }
    }

    &__nextBtnWrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
    &__nextAvailableTimeBtn {
      border: 1px solid var(--bokamera-primary);
      border-radius: 4px;
      background-color: var(--bokamera-primary);
      color: var(--bokamera-bg-color);
      max-height: 80px;
      padding: 10px 20px;
    }

    &__head {
      display: flex;
    }
    &__body {
      display: flex;
      min-height: 270px;
    }
    &__column {
      display: flex;
      flex-direction: column;
      margin: 1px;
      &--noTimes {
        background: var(--bokamera-grey-200);
        font-weight: 300;
        font-size: 1.4rem;
        letter-spacing: 0.4rem;
        writing-mode: tb-rl;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--bokamera-grey-500);
      }
      &--loading {
        color: transparent;
      }
    }
    &__dayHeader {
      width: calc(100% / 7);
      text-align: center;
    }
    &__day {
      text-align: center;
      margin: auto;
      font-size: 24px;
      font-weight: lighter;
    }
    &__month {
      text-align: center;
      margin: auto;
      color: #888;
      font-size: 12px;
      font-weight: lighter;
    }
    &__dayOfWeek {
      text-align: center;
      margin: auto;
      font-size: 18px;
      font-weight: 400;
    }
    &__timeSlot {
      cursor: pointer;
      border: none;
      margin: 1px;
      padding: 2px;
      width: 100%;
      border-radius: 6px;
      background: var(--bokamera-success-light);
      color: #000;

      &:hover {
        filter: brightness(0.9);
      }

      &--fullyBooked {
        cursor: auto;
        background: var(--bokamera-danger-light);
        color: rgb(78, 53, 53);
        &:hover {
          background: var(--bokamera-danger-light);
        }
        .CalendarWeek__time {
          text-decoration: line-through;
        }
      }
      &--addToQueue {
        /* border: 2px solid red; */
        background: var(--bokamera-warning-light);
        color: rgb(78, 53, 53);
        &:hover {
          background: var(--bokamera-warning-light);
        }
      }
   
      svg {
        vertical-align: baseline;
      }
    }
    &__serviceName {
      padding-bottom: 4px;
      font-weight: bold;
    }
    &__time {
      display: flex;
      justify-content: center;
      padding: 4px;
    }
    &__timeAndName {
      padding: 4px;
    }
    &__freeTimesLeft {
      font-size: 14px;
      background: white;
      padding: 2px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    @media (max-width: 600px) {
      &__timeSlot {
        padding: 8px 4px;
        font-size: 12px;
      }
    }
  }
}
