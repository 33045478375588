#bokamera-embedded {
    .Company {
        display: block;
        &__title {
            padding: 0.3rem;
        }
        &__city {
            padding: 0.3rem;
        }
    }
}
