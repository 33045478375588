#bokamera-embedded {
    .CalendarToolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;

        &__button {
            min-width: 0;
        }
        &__button:not(:last-of-type) {
            margin-right: 10px;
        }
    }
}
