#bokamera-embedded {
  .ShowMoreText {
    min-width: 50px;

    &__link {
      color: var(--bokamera-primary);
    }
    @media only screen and (max-width: 600px) {
      overflow-wrap: anywhere;
    }
  }
}