#bokamera-embedded {
  .Confirm {
    .text-muted {
      color: var(--bokamera-text-color) !important;
    }
    display: flex;
    flex-wrap: wrap;

    &__bookButtons {
      transition: box-shadow 0.3s ease-in-out, position 0.1s;
    }

    &__bookButtons--relative {
      position: relative;
      bottom: 0!important;
    }

    &__subscribeColumn {
      display: flex;
      gap: 8px;
    }

    &__bookButtons--fixed {
      @media only screen and (max-width: 900px) {
        position: fixed;
        left: 20px;
        right: 20px;
        padding: 4px;
        box-shadow: 5px 5px 24px rgba(0,0,0,0.8);
        background: var(--bokamera-white);
      }
    }

    &__loader--absolute {
      @media only screen and (max-width: 900px) {
        position: absolute;
        top: calc(50% - 20px);
        right: 0;
        left: 0;
      }
    }

    @media only screen and (min-width: 901px) {
      &__placeholder {
        height: 0!important;
      }
    }

    &__submitButton {
      width: 100%;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      @media only screen and (max-width: 900px) {
        width: 100%;
      }
    }
    &__form {
      min-width: 360px;
    }
    &__summary {
      max-width: 360px;
      padding: 0;
    }
    &__summaryBody {
      background-color: var(--bokamera-grey-100);
      border: 1px solid var(--bokamera-bg-color);
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      padding: 0.75rem 1.25rem;
    }
    &__summaryFooter {
      background-color: var(--bokamera-bg-color);
      padding: 1.75rem 1.25rem;
    }
    &__agreement {
      font-size: 14px;
      margin-bottom: 1em;
    }
    &__row {
      margin-left: -15px;
      margin-right: -15px;
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
    }
    &__mainCol,
    &__summaryCol {
      margin-left: 15px;
      margin-right: 15px;
    }
    &__mainCol {
      width: calc(60% - 30px);
    }
    &__summaryCol {
      width: calc(40% - 30px);
      .card {
        position: sticky;
        top: 20px;
      }
    }
    &__orText {
      &.m-2 {
        margin-top: 0.75em;
        margin-bottom: 0.75em;
      }
      text-align: center;
    }
    &__quickActions {
      margin-top: 1em;
      margin-bottom: 1em;
    }
    &__cancel {
      background-color: var(--bokamera-bg-color);
    }
    &__ok {
      background-color: var(--bokamera-bg-color);
    }
    @media (max-width: 600px) {
      &__subscribeColumn {
        justify-content: center;
      }
    }
    @media (max-width: 900px) {
      &__mainCol,
      &__summaryCol {
        width: 100%;
        flex-basis: 100%;
      }
    }
  }
}
