#bokamera-embedded {
    .Resources {
        &__list {
            display: flex;
            overflow-y: auto;
            padding-bottom: 1rem;
            gap: 0.75rem;
            flex-wrap: wrap;
            max-height: 230px;
            @media only screen and (max-width: 600px) {
                justify-content: center;
            }
        }
        &__resource {
            @media only screen and (max-width: 600px) {
                flex-basis: 30%;
            }
        }
    }
}
