#bokamera-embedded {
    .TimeSlot {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, opacity 0.15s ease-in-out;
        min-width: 7px;
        cursor: pointer;
        opacity: none;
        border: 1px solid var(--bokamera-grey-500);
        color: var(--bokamera-text-color);
        box-sizing: border-box;
        border-radius: 5px;
        background: none;

        &:hover {
            border-color: var(--bokamera-primary);
            color: var(--bokamera-primary);
        }
        &--disabled,
        &--disabled:hover {
            border: 1px solid var(--bokamera-grey-300);
            color: var(--bokamera-grey-300);
            text-decoration: line-through;
            cursor: auto;
        }
        &--addToQueue {
            background: var(--bokamera-warning-light);
            color: rgb(78, 53, 53);
            &:hover {
              background: var(--bokamera-warning-light);
            }
        }
        svg {
            vertical-align: baseline;
        }
    }
}
