#bokamera-embedded {
    .TimesService {
        &__calendarContainer {
            position: relative;
        }
        &__loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &__calendarBody {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }
        &__calendarBody--loading {
            opacity: 0.4;
        }
    }
}
