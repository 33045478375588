#bokamera-embedded {
    .PromoCode {
        padding: 6px;
        margin-bottom: 1rem;

        &__toggle {
            color: var(--bokamera-primary);
            background: none;
            border: none;
            padding: 0;

            &:hover {
                color: #0056b3;
            }
        }
        &__controls {
            display: flex;
            margin-top: 1em;

            &--hidden {
                display: none;
            }
        }
        &__input {
            width: 20em;
            margin-right: 0.5em;
        }
        &__error {
            margin-top: 0.4em;
            color: var(--bokamera-danger);
        }
    }
}
